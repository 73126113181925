import Container_CircleAreaComponent from '@wix/thunderbolt-elements/src/components/Container/viewer/skinComps/CircleArea/CircleArea.skin';


const Container_CircleArea = {
  component: Container_CircleAreaComponent
};


export const components = {
  ['Container_CircleArea']: Container_CircleArea
};

